import ThankYouPage from "components/thank-you/ThankYouPage";
import debug from "debug";
import queryString from "query-string";
import React, { useEffect } from "react";
import Helmet from "react-helmet";

import GlobalLayout from "../../components/page/GlobalLayout";

const log = debug("ThankYou");

export default function ThankYou({ location }) {
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    if (window && window.ga) {
      log("google conversion lead was tracked");
      window.ga("event", "conversion", {
        send_to: "AW-970586936/DNEjCJ7f15UCELj2584D",
      });
    }
  }, []);

  return (
    <GlobalLayout>
      <Helmet>
        <title>Thank You</title>
        <meta
          name="description"
          content="Thank you! Someone from the Rhombus team will be in contact with you shortly."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ThankYouPage formId={parsed.formId} />
    </GlobalLayout>
  );
}
