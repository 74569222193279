import React, { useEffect, useState } from "react";

import ExperienceCards from "./ExperienceCards";
import { getParam } from "components/form/util/functions";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";

export default function ThankYouPage() {
  const [blurb, setBlurb] = useState(
    "We&apos;ve received your request and the Rhombus team will be reaching out regarding next steps."
  );
  const [title, setTitle] = useState("Thank you for your interest!");

  useEffect(() => {
    const formId = getParam("formId");
    if (formId === "webinar") {
      setBlurb(
        "Thank you for your interest! You’ll receive a confirmation email with webinar details and a link to add the event to your calendar."
      );
    }
    if (formId === "blog-subscription") {
      setBlurb("Thank you for your interest!");
    }
    if (formId === "referral_program") {
      setBlurb(
        "We will call or email your friend soon. Visit our <a href='/lp/referral/#faqs'>FAQs</a> for more information about payout eligibility and timing."
      );
      setTitle("Thank You For Sharing Rhombus!");
    }
    if (formId === "BOCES_event") {
      setTitle("Thank you for registering!");
      setBlurb("Please keep an eye out for your confirmation email.");
    }
  }, []);

  return (
    <SectionContainer>
      <SectionInner style={{ flexDirection: "column", gap: "5rem" }}>
        <SectionCol style={{ width: "100%" }}>
          <TextContainer>
            <FancyPageTitle>{title}</FancyPageTitle>
            <MainParagraph dangerouslySetInnerHTML={{ __html: blurb }} />
          </TextContainer>
        </SectionCol>
        <ExperienceCards />
      </SectionInner>
    </SectionContainer>
  );
}
