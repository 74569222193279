export const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "District of Columbia",
];

export const PROVINCES = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Nova Scotia",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Northwest Territories",
  "Nunavut",
  "Yukon",
];

export const BLACKLISTED_PROVIDERS = [
  "gmail.com",
  "hotmail.com",
  "yahoo.com",
  "outlook.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "live.com",
  "yandex.com",
  "protonmail.com",
  "zoho.com",
  "gmx.com",
  "inbox.com",
  "rocketmail.com",
  "att.net",
  "msn.com",
  "cox.net",
  "sbcglobal.net",
  "verizon.net",
  "me.com",
  "mac.com",
  "bellsouth.net",
  "earthlink.net",
  "ymail.com",
  "optonline.net",
  "web.de",
  "mail.ru",
  "t-online.de",
  "comcast.net",
  "ntlworld.com",
  "btinternet.com",
  "yahoo.co.uk",
  "hotmail.co.uk",
  "orange.fr",
  "free.fr",
  "wanadoo.fr",
  "live.co.uk",
  "rediffmail.com",
  "o2.pl",
  "seznam.cz",
  "abv.bg",
  "rambler.ru",
  "libero.it",
  "alice.it",
  "uol.com.br",
  "bol.com.br",
  "hotmail.com.br",
  "ig.com.br",
  "yahoo.com.br",
  "globo.com",
  "terra.com.br",
  "outlook.com.br",
  "yahoo.co.in",
  "hotmail.co.in",
  "rediffmail.com",
  "yahoo.co.id",
  "hotmail.co.id",
  "indiatimes.com",
  "yahoo.co.jp",
  "yahoo.co.kr",
  "naver.com",
  "hanmail.net",
  "yahoo.com.my",
  "hotmail.my",
  "yahoo.com.ph",
  "hotmail.ph",
  "yahoo.com.sg",
  "hotmail.sg",
  "yahoo.co.th",
  "hotmail.co.th",
  "yahoo.com.vn",
  "hotmail.com.vn",
  "yahoo.com.tr",
  "hotmail.com.tr",
  "mail.bg",
  "abv.net",
  "163.com",
  "126.com",
  "qq.com",
  "sina.com",
  "sohu.com",
  "tom.com",
  "163.net",
  "yeah.net",
  "vip.163.com",
  "vip.126.com",
  "139.com",
  "yahoo.com.cn",
  "hotmail.com.cn",
  "live.cn",
  "msn.cn",
  "qq.com",
  "gmail.co.uk",
  "gmail.co",
  "hotmail.fr",
  "live.fr",
  "laposte.net",
  "yahoo.fr",
  "hotmail.de",
  "live.de",
  "yahoo.de",
  "gmx.de",
  "web.de",
  "mail.de",
  "hotmail.it",
  "live.it",
  "yahoo.it",
  "libero.it",
  "gmx.at",
  "web.at",
  "mail.at",
  "yahoo.com.ar",
  "hotmail.com.ar",
  "yahoo.com.mx",
  "hotmail.com.mx",
  "yahoo.ca",
  "hotmail.ca",
  "live.ca",
  "yahoo.com.au",
  "hotmail.com.au",
  "live.com.au",
  "yahoo.es",
  "hotmail.es",
  "live.com.es",
  "yahoo.co.za",
  "hotmail.co.za",
  "live.co.za",
  " work.com",
  "rytha.com",
  "business.com",
  "busines.com",
  "proton.me",
  "uma3.be",
  "outlook.co",
  "eaglesmail.net",
  "cheungri.com",
  "jake22.xyz",
  "clientcaf.info",
];
